import React, { Fragment } from "react";
import './App.css';
import ListPosts from "./ListPosts";

import NewPost from './NewPost.js'
// const bootstrap = require('bootstrap')

function App() {
  return (
    <div className="App">
      <Fragment>
        <div className="container">
          <NewPost/>
          <ListPosts/>
        </div>
      </Fragment>
    </div>
  );
}

export default App;