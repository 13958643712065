import React, {Fragment, useEffect, useState} from "react";


const ListPosts = () => {
    const [posts, setPosts] = useState([]);

    const getPosts = async () => {
        try {
            const response = await fetch("https://worker.henrygeneralassignment.workers.dev/posts");
            const jsonData = await response.json();
            setPosts(jsonData);
        } catch (err) {
            console.error(err.message);
        }
    }

    useEffect(() => {
        getPosts();
    }, []);

    return (
        <Fragment>
            <div className="bg-light">
                <table className="table mt-5 text-center">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Title</th>
                            <th>Content</th>   
                        </tr>
                    </thead>
                    <tbody>
                        {posts.map(post => (
                            <tr key={post.id}>
                                <td>{post.username}</td>
                                <td>{post.title}</td>
                                <td>{post.content}</td>                         
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default ListPosts;