// I used some code from a project I am working on as a template

import React, {Fragment, useState} from "react";

const NewPost = () => {
    const [post, setPost] = useState({
        title: '',
        username:'',
        content: ''
    });
        function handleValueChange(evt){
        const value = evt.target.value
        setPost({
            ...post,
            [evt.target.name]: value
        })
    }
    const onSubmitForm = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch ("https://worker.henrygeneralassignment.workers.dev/posts", {
                method: "POST",
                mode: "cors",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(post)
            });
            console.log(response)
            window.location = "/"; //reload
        } catch (err) {
            console.error(err.message);
        }
    }
    return (
        <Fragment>
            <div className="bg-light">
                <h1 className="text-center mt-5">Add a new Post</h1>
                <form className="d-flex mt-5" onSubmit={onSubmitForm}>
                    <input
                        type="text"
                        className="form-control"
                        name="username"
                        value={post.username}
                        placeholder="Username"
                        onChange={handleValueChange}
                    />
                    <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={post.title}
                        placeholder="Title"
                        onChange={handleValueChange}
                    />
                    <input
                        type="text"
                        className="form-control"
                        name="content"
                        value={post.content}
                        placeholder="Content"
                        onChange={handleValueChange}
                    />

                    <button className="btn btn-success" onClick={onSubmitForm}>Add</button>
                </form>
            </div>
        </Fragment>
    )
}
export default NewPost;